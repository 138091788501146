@import '../../../../../styles/abstracts/index';

.body {
  padding: spacing(12);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: spacing(4);
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: spacing(2);
}

.row {
  width: 100%;
  text-align: center;
}

.buttonRow {
  margin-top: spacing(2);
}
